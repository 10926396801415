<template>
  <section
    id="comparison-school"
    class="co-bg-striped px-4 py-20"
  >
    <div class="co-container-lg">
      <h2 class="co-heading-2-caption">
        他スクールとの比較
      </h2>
      <p class="mb-12 text-center text-co-gray-600">
        <span class="inline-block md:block">他のスクールと比較するとテックアカデミーの</span><span class="inline-block md:block">圧倒的なコストパフォーマンスをご確認いただけます。</span>
      </p>
      <div class="overflow-x-auto">
        <table class="te-comparison-school-switch-table table mb-4 text-center">
          <thead>
            <tr class="te-head-tr h-24">
              <th style="width: 19%" />
              <th
                class="te-teckacademy-cell bg-co-primary-500 align-middle"
                style="width: 27%"
              >
                <picture>
                  <source
                    media="(max-width: 767.98px)"
                    srcset="~/assets/images/Te/TeCommon/Logo/logo-vertical.svg"
                    width="55"
                    height="45"
                  >
                  <img
                    class="mx-auto inline w-3/4"
                    src="~/assets/images/Te/TeCommon/Logo/logo.svg"
                    alt="テックアカデミー"
                    width="300"
                    height="39"
                    loading="lazy"
                  >
                </picture>
              </th>
              <th
                class="te-normal-cell bg-co-gray-400 align-middle text-white"
                style="width: 18%"
              >
                <span class="te-switch-block">
                  A社
                </span>
              </th>
              <th
                class="te-normal-cell bg-co-gray-400 align-middle text-white"
                style="width: 18%"
              >
                <span class="te-switch-block">
                  B社
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="te-bg-tr te-h-lg-100px">
              <th class="te-head-cell border-t align-middle">
                <span class="te-switch-block">
                  副業案件保証
                </span>
              </th>
              <td class="te-teckacademy-cell align-middle">
                <span class="te-switch-block">
                  <span class="mb-2 inline-block">
                    <FontAwesomeIcon
                      :icon="faCircleO"
                      class="te-icon-dobble-circle co-display-5"
                    />
                  </span>
                  <br>
                  <span class="co-marker-yellow-1  font-bold">
                    5万円分の案件提供を保証
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="inline-block">
                    <FontAwesomeIcon
                      :icon="faXmark"
                      class="co-display-7 text-co-gray-400"
                    />
                  </span>
                  <br>
                  <span>
                    まずは転職を目指す
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="inline-block">
                    <FontAwesomeIcon
                      :icon="faXmark"
                      class="co-display-7 text-co-gray-400"
                    />
                  </span>
                  <br>
                  <span>
                    提携サービスあり
                  </span>
                </span>
              </td>
            </tr>
            <tr class="te-bg-tr te-h-lg-100px">
              <th class="te-head-cell align-middle">
                <span class="te-switch-block">
                  案件の進行サポート
                </span>
              </th>
              <td class="te-teckacademy-cell align-middle">
                <span class="te-switch-block">
                  <span class="mb-2 inline-block">
                    <FontAwesomeIcon
                      :icon="faCircleO"
                      class="te-icon-dobble-circle co-display-5"
                    />
                  </span>
                  <br>
                  <span class="block font-bold md:inline-block">
                    現役エンジニアの
                  </span>
                  <span class="block font-bold md:inline-block">
                    サポートで安心
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="inline-block">
                    <FontAwesomeIcon
                      :icon="faXmark"
                      class="co-display-7 text-co-gray-400"
                    />
                  </span>
                  <br>
                  <span>
                    なし
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="inline-block">
                    <FontAwesomeIcon
                      :icon="faXmark"
                      class="co-display-7 text-co-gray-400"
                    />
                  </span>
                  <br>
                  <span>
                    なし
                  </span>
                </span>
              </td>
            </tr>

            <tr
              v-if="bannerguarantee"
              class="te-bg-tr te-h-lg-100px"
            >
              <th class="te-head-cell border-t align-middle">
                <span class="te-switch-block">
                  実案件の提供
                </span>
              </th>
              <td class="te-teckacademy-cell align-middle">
                <span class="te-switch-block">
                  <span class="mb-2 inline-block">
                    <FontAwesomeIcon
                      :icon="faCircleO"
                      class="te-icon-dobble-circle co-display-5"
                    />
                  </span>
                  <br>
                  <span class="font-bold">
                    3案件を確約
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="inline-block">
                    <FontAwesomeIcon
                      :icon="faXmark"
                      class="co-display-7 text-co-gray-400"
                    />
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="inline-block">
                    <FontAwesomeIcon
                      :icon="faXmark"
                      class="co-display-7 text-co-gray-400"
                    />
                  </span>
                </span>
              </td>
            </tr>
            <tr class="te-bg-tr te-h-lg-100px">
              <th class="te-head-cell align-middle">
                <span class="te-switch-block">
                  転職サポート
                </span>
              </th>
              <td class="te-teckacademy-cell align-middle">
                <span class="te-switch-block">
                  <span class="mb-1 inline-block">
                    <FontAwesomeIcon
                      :icon="faCircle"
                      class="co-display-5 leading-none text-co-gray-700"
                    />
                  </span>
                  <br>
                  <span class="block md:inline-block">
                    副業で経験を積んだ
                  </span>
                  <span class="block md:inline-block">
                    後に転職も可能
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="inline-block">
                    <FontAwesomeIcon
                      :icon="faCircle"
                      class="co-display-8 leading-none text-co-gray-700"
                    />
                  </span>
                  <br>
                  <span>
                    条件付き転職保証
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="inline-block">
                    <FontAwesomeIcon
                      :icon="faXmark"
                      class="co-display-7 text-co-gray-400"
                    />
                  </span>
                  <br>
                  <span>
                    提携サービスあり
                  </span>
                </span>
              </td>
            </tr>
            <tr class="te-bg-tr te-h-lg-100px">
              <th class="te-head-cell align-middle">
                <span class="te-switch-block">
                  講師の特徴
                </span>
              </th>
              <td class="te-teckacademy-cell align-middle">
                <span class="te-switch-block">
                  <span class="mb-2 inline-block">
                    <FontAwesomeIcon
                      :icon="faCircleO"
                      class="te-icon-dobble-circle co-display-5"
                    />
                  </span>
                  <br>
                  <span class="block font-bold md:inline-block">
                    全員が実務経験3年
                  </span>
                  <span class="block font-bold md:inline-block">
                    以上の現役エンジニア
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="mb-2 inline-block">
                    <FontAwesomeIcon
                      :icon="faTriangle"
                      class="co-display-8 text-co-gray-400"
                    />
                  </span>
                  <br>
                  <span>
                    大学生や卒業生が中心
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="inline-block">
                    <FontAwesomeIcon
                      :icon="faCircle"
                      class="co-display-8 leading-none text-co-gray-700"
                    />
                  </span>
                  <br>
                  <span>
                    卒業生などの実務経験者
                  </span>
                </span>
              </td>
            </tr>

            <tr
              v-if="mentoring"
              class="te-bg-tr te-h-lg-100px "
            >
              <th class="te-head-cell align-middle">
                <span class="te-switch-block">
                  マンツーマンメンタリング
                </span>
              </th>
              <td class="te-teckacademy-cell align-middle">
                <span class="te-switch-block">
                  <span class="mb-1 inline-block">
                    <FontAwesomeIcon
                      :icon="faCircleO"
                      class="te-icon-dobble-circle co-display-5"
                    />
                  </span>
                  <br>
                  <span class="block md:inline-block">
                    現役エンジニアが
                  </span>
                  <span class="block md:inline-block">
                    専任で担当
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="inline-block">
                    <FontAwesomeIcon
                      :icon="faXmark"
                      class="co-display-7 text-co-gray-400"
                    />
                  </span>
                  <br>
                  <span>
                    なし
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="inline-block">
                    <FontAwesomeIcon
                      :icon="faXmark"
                      class="co-display-7 text-co-gray-400"
                    />
                  </span>
                  <br>
                  <span>
                    なし
                  </span>
                </span>
              </td>
            </tr>
            <!-- バーチャルキャンパス -->
            <tr
              v-if="virtualCampus"
              class="te-bg-tr te-h-lg-100px"
            >
              <th class="te-head-cell align-middle">
                <span class="te-switch-block">
                  バーチャルキャンパス
                </span>
              </th>
              <td class="te-teckacademy-cell align-middle">
                <span class="te-switch-block">
                  <span class="mb-1 inline-block">
                    <FontAwesomeIcon
                      :icon="faCircle"
                      class="co-display-5 leading-none text-co-gray-700"
                    />
                  </span>
                  <br>
                  <span>
                    24時間ログイン可能、講師常駐
                    <span class="co-display-14">※</span>
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="inline-block">
                    <FontAwesomeIcon
                      :icon="faXmark"
                      class="co-display-7 text-co-gray-400"
                    />
                  </span>
                  <br>
                  <span>
                    なし
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="inline-block">
                    <FontAwesomeIcon
                      :icon="faXmark"
                      class="co-display-7 text-co-gray-400"
                    />
                  </span>
                  <br>
                  <span>
                    なし
                  </span>
                </span>
              </td>
            </tr>

            <!-- 学習コンテンツ -->
            <tr
              v-if="learningContent"
              class="te-bg-tr te-h-lg-100px"
            >
              <th class="te-head-cell align-middle">
                <span class="te-switch-block">
                  学習コンテンツ
                  <br>
                  （イベント）
                </span>
              </th>
              <td class="te-teckacademy-cell align-middle">
                <span class="te-switch-block">
                  <span class="mb-1 inline-block">
                    <FontAwesomeIcon
                      :icon="faCircle"
                      class="co-display-5 leading-none text-co-gray-700"
                    />
                  </span>
                  <br>
                  <span>
                    24時間ログイン可能、講師常駐
                    <span class="co-display-14">※</span>
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="inline-block">
                    <FontAwesomeIcon
                      :icon="faXmark"
                      class="co-display-7 text-co-gray-400"
                    />
                  </span>
                  <br>
                  <span>
                    なし
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="inline-block">
                    <FontAwesomeIcon
                      :icon="faXmark"
                      class="co-display-7 text-co-gray-400"
                    />
                  </span>
                  <br>
                  <span>
                    なし
                  </span>
                </span>
              </td>
            </tr>
            <!-- チャット質問 -->
            <tr class="te-bg-tr te-h-lg-100px">
              <th class="te-head-cell align-middle">
                <span class="te-switch-block">
                  チャット質問
                </span>
              </th>
              <td class="te-teckacademy-cell align-middle">
                <span class="te-switch-block">
                  <span class="mb-2 inline-block">
                    <FontAwesomeIcon
                      :icon="faCircleO"
                      class="te-icon-dobble-circle co-display-5"
                    />
                  </span>
                  <br>
                  <span class="block font-bold md:inline-block">
                    サポート時間内に
                  </span>
                  <span class="block font-bold md:inline-block">
                    現役エンジニアが即対応
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="inline-block">
                    <FontAwesomeIcon
                      :icon="faCircle"
                      class="co-display-8 leading-none text-co-gray-700"
                    />
                  </span>
                  <br>
                  <span>
                    サポート時間内に講師が対応
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="inline-block">
                    <FontAwesomeIcon
                      :icon="faCircle"
                      class="co-display-8 leading-none text-co-gray-700"
                    />
                  </span>
                  <br>
                  <span>
                    サポート時間内に講師が対応
                  </span>
                </span>
              </td>
            </tr>

            <!-- カリキュラム -->
            <tr class="te-bg-tr te-h-lg-100px">
              <th class="te-head-cell align-middle">
                <span class="te-switch-block">
                  カリキュラム
                </span>
              </th>
              <td class="te-teckacademy-cell align-middle">
                <span class="te-switch-block">
                  <span class="mb-1 inline-block">
                    <FontAwesomeIcon
                      :icon="faCircle"
                      class="co-display-5 leading-none text-co-gray-700"
                    />
                  </span>
                  <br>
                  <span>
                    副業に必要なスキルを掲載
                    <br>
                    無期限で閲覧可
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="mb-2 inline-block">
                    <FontAwesomeIcon
                      :icon="faTriangle"
                      class="co-display-8 text-co-gray-400"
                    />
                  </span>
                  <br>
                  <span>
                    転職に必要なスキルを掲載
                    <br>
                    卒業後1年間は閲覧可
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="inline-block">
                    <FontAwesomeIcon
                      :icon="faCircle"
                      class="co-display-8 leading-none text-co-gray-700"
                    />
                  </span>
                  <br>
                  <span>
                    副業に必要なスキルを掲載
                    <br>
                    無期限で閲覧可
                  </span>
                </span>
              </td>
            </tr>
            <!-- 期間 -->
            <tr class="te-bg-tr te-h-lg-100px">
              <th class="te-head-cell align-middle">
                <span class="te-switch-block">
                  期間
                </span>
              </th>
              <td class="te-teckacademy-cell align-middle">
                <span class="te-switch-block">
                  <span class="mb-1 inline-block">
                    <FontAwesomeIcon
                      :icon="faCircle"
                      class="co-display-5 leading-none text-co-gray-700"
                    />
                  </span>
                  <br>
                  <span class="block font-bold md:inline-block">
                    ライフスタイルに
                  </span>
                  <span class="block font-bold md:inline-block">
                    合わせて選べる
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="mb-2 inline-block">
                    <FontAwesomeIcon
                      :icon="faTriangle"
                      class="co-display-8 text-co-gray-400"
                    />
                  </span>
                  <br>
                  <span>
                    最短10週間
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="mb-2 inline-block">
                    <FontAwesomeIcon
                      :icon="faTriangle"
                      class="co-display-8 text-co-gray-400"
                    />
                  </span>
                  <br>
                  <span>
                    90日間
                  </span>
                </span>
              </td>
            </tr>

            <!-- 給付金対象 -->
            <tr
              v-if="benefits"
              class="te-bg-tr te-h-lg-100px"
            >
              <th class="te-head-cell align-middle">
                <span class="te-switch-block">
                  給付金対象
                </span>
              </th>
              <td class="te-teckacademy-cell align-middle">
                <span class="te-switch-block font-bold">
                  <span class="mb-2 inline-block">
                    <FontAwesomeIcon
                      :icon="faCircleO"
                      class="te-icon-dobble-circle co-display-5"
                    />
                  </span>
                  <br>
                  対象
                  <br>
                  <span v-if="virtualCampus || learningContent">
                    最大15万円以上還元
                  </span>
                  <span v-else>
                    最大56万円以上還元
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="inline-block">
                    <FontAwesomeIcon
                      :icon="faXmark"
                      class="co-display-7 text-co-gray-400"
                    />
                  </span>
                  <br>
                  <span>
                    対象外
                  </span>
                </span>
              </td>
              <td class="te-normal-cell co-display-13 align-middle">
                <span class="te-switch-block">
                  <span class="inline-block">
                    <FontAwesomeIcon
                      :icon="faXmark"
                      class="co-display-7 text-co-gray-400"
                    />
                  </span>
                  <br>
                  <span>
                    対象外
                  </span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="co-display-13 mb-0 mt-1 text-co-gray-500">
        <span v-if="virtualCampus || learningContent">
          ※19時から23時
        </span>
        <span v-else>
          ※案件保証は実力判定テスト合格した場合のみ
        </span>
      </p>
    </div>
  </section>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTriangle, faCircleO, faCircle, faXmark } from '@awesome.me/kit-65d95819ce/icons/classic/regular'

</script>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    // 給付金対象
    benefits: {
      type: Boolean,
      default: false,
    },
    // メンタリング
    mentoring: {
      type: Boolean,
      default: false,
    },
    // バーチャルキャンパス
    virtualCampus: {
      type: Boolean,
      default: false,
      required: false,
    },
    // 学習コンテンツ
    learningContent: {
      type: Boolean,
      default: false,
      required: false,
    },
    // バナー案件保証
    bannerguarantee: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style scoped>
/* borderリセット */
.table :where(thead tr, tbody tr:not(:last-child),
tbody tr:first-child:last-child) {
  border: none;
}

.table :where(th, td) {
  @media (max-width: 767.98px) {
    @apply pb-0;
  }
  @apply px-3;
}

.te-comparison-school-switch-table {
  @media (max-width: 767.98px) {
    writing-mode: vertical-lr;
  }
}

.te-switch-block {
  @media (max-width: 767.98px) {
    white-space: nowrap;
    writing-mode: horizontal-tb;
  }
}

.te-head-cell {
  @media (max-width: 767.98px) {
    height: 50px;
    border: none !important;
  }
  @media (min-width: 768px) {
    border-left: 1px solid #dee2e6;
  }
}

.te-normal-cell {
  @media (max-width: 767.98px) {
    border-bottom: 1px solid #dee2e6;
  }
  @media (min-width: 768px) {
    border-right: 1px solid #dee2e6;
  }
}

.te-h-lg-100px {
  @media (min-width: 768px) {
    height: 100px;
  }
  &:last-of-type .te-normal-cell {
    @media (max-width: 767.98px) {
      border-right: 1px solid #dee2e6;
    }
  }
}

.te-head-tr {
  th:nth-child(2) {
    @media (max-width: 767.98px) {
      height: 150px;
    }
  }
  th:nth-child(n + 3) {
    @media (max-width: 767.98px) {
      height: 112px;
    }
  }
}

.te-bg-tr {
  /* 交互の背景色 */
  &:nth-child(even) {
    td {
      background: #f7f9f9;
    }
    @media (min-width: 768px) {
      th {
        background: #f7f9f9;
      }
    }
  }
  &:nth-child(odd) {
    td {
      background: #fff;
    }
    @media (min-width: 768px) {
      th {
        background: #fff;
      }
    }
  }
  &:last-of-type {
    .te-head-cell,
    td {
      @media (min-width: 768px) {
        border-bottom: 1px solid #dee2e6;
      }
    }
  }
}

.te-teckacademy-cell {
  @media (max-width: 767.98px) {
    border-top: 4px solid #0b5770;
    border-bottom: 4px solid #0b5770;
  }
  @media (min-width: 768px) {
    border-right: 8px solid #0b5770;
    border-left: 8px solid #0b5770;
  }
  .te-head-tr & {
    @media (min-width: 768px) {
      border-top: 8px solid #0b5770;
    }
  }
  .te-bg-tr:last-of-type & {
    @media (max-width: 767.98px) {
      border-right: 4px solid #0b5770;
    }
    @media (min-width: 768px) {
      border-bottom: 8px solid #0b5770;
    }
  }
}

.te-icon-dobble-circle {
  color: #1f7f96;
}

.co-marker-yellow-1 {
  background: linear-gradient(transparent 70%, #ff3 0);
}
</style>
